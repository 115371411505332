import React from "react"
import { Helmet } from "react-helmet"
import { BulletItem, BulletList } from "../../components/BulletList/BulletList"
import Layout, { Headline } from "../../components/layout"
import Telephone from "../../components/telephone/telephone"
import Video from "../../components/video/video"
import CarouselPurchases from "../../components/carousels/purchases"
import bg from "../../assets/img/2423.jpg"

export default function WhereWeBuy() {
  return (
    <Layout
      bg={bg}
      header={"Southern California's Leading HOME BUYERS!"}
      subheader={"ANY Property - ANY Condition - CASH in 10 days!"}
    >
      <Helmet>
        <title>Where We Buy Homes in Southern California | Direct Buyers</title>
        <meta
          name="description"
          content="We buy homes & other properties in as-is condition throughout Los Angeles, Riverside, San Bernardino, Orange, Ventura & San Diego County. Call today! 844-242-SELL"
        />
      </Helmet>

      <div className={`container`}>
        <Headline>
          <h1>Where We Buy Houses in Southern California</h1>
        </Headline>
        <div className={`row`}>
          <div className={`col-md-6`}>
            <p>We buy AS-IS! ANY property, ANY condition, CASH in 10 days!</p>
            <BulletList singleCol highQtyCopy>
              <BulletItem>
                We are local to Southern California and service the following
                counties: Los Angeles, Riverside, San Bernardino, Orange,
                Ventura & San Diego County
              </BulletItem>
              <BulletItem>
                Our reliability and personal customer service is what sets us
                apart from other home buyers.
              </BulletItem>
              <BulletItem>
                Most commonly we purchase: single family homes, duplex/multi
                family buildings, condos, land and commercial buildings!
              </BulletItem>
              <BulletItem>
                We purchase in AS-IS CONDITION sight unseen! No Repairs, NO Fees
                NO Commissions. Never another open house! Best of all, we pay
                the Seller's escrow fees!
              </BulletItem>
              <BulletItem>
                Whether your property is tenant occupied, vacant, boarded up or
                you live in it - We will consider all circumstances!
              </BulletItem>
              <BulletItem>
                Inherited a home? Need to go thru probate? Multiple owners on
                title? NO PROBLEM!
              </BulletItem>
              <BulletItem>
                We have helped hundreds of sellers sell their homes fast & we
                can do the same for you!
              </BulletItem>
              <BulletItem>
                Enjoy a fast & hassle-free process whether the home is in
                perfect condition or is a distressed property.
              </BulletItem>
              <BulletItem>
                We understand that some homeowners need to sell their properties
                fast, and sometimes do not have the money to make necessary
                repairs. Other struggles may be finding a suitable buyer that is
                able to get a loan in time.
              </BulletItem>
              <BulletItem>
                When selling your home, it is important to pick a reliable
                buyer. We are known in the industry and stand behind our offers.
                We close as agreed or YOU keep our deposit!
              </BulletItem>
              <BulletItem>
                Show us our competitors' written offers and most likely we can
                beat it! Call us for more details & a NO Obligation offer:{" "}
                <Telephone />
              </BulletItem>
            </BulletList>
          </div>
          <div className={`col-md-5 offset-md-1`}>
            <Video url={"https://www.youtube.com&v=RncA0_j1zgw"} />
          </div>
        </div>
        <CarouselPurchases />
      </div>
    </Layout>
  )
}
