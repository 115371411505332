import React from "react"
import styled from "styled-components"

const VideoFrame = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

const ranTitle = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

const Video = ({ url }) => {
  let video_id = url.split("v=")[1]
  let ampersandPosition = video_id.indexOf("&")
  if (ampersandPosition !== -1) {
    video_id = video_id.substring(0, ampersandPosition)
  }

  return (
    <VideoFrame>
      <iframe
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + video_id + "?rel=0"}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title={ranTitle()}
        allowFullScreen
      />
    </VideoFrame>
  )
}

export default Video
