import React from "react";
import styled from "styled-components";
import mq from '../../styles/media-query';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
  font-size: 16px;
  ${mq.xs`
    font-size: ${props => props.highQtyCopy ? 16 : 22}px;
  `}
  ${mq.sm`
    font-size: ${props => props.highQtyCopy ? 18 : 26}px;
  `}
  ${mq.md`
    grid-template-columns: ${props => props.singleCol ? '100%' : '50% 50%'};
    font-size: ${props => props.highQtyCopy ? 20 : 26}px;
  `};
`;

const BulletItemWrapper = styled.div`
  display: flex;
  text-align: left;
  align-items: flex-start;
  i {
    color: var(--brandPrimary);
    margin-right: 10px;
  }
  span {
    line-height: 1;
  }
`;

export const BulletItem = ({children}) => {
    return (
        <BulletItemWrapper>
            <i className={`far fa-check-circle`} /><span>{children}</span>
        </BulletItemWrapper>
    )
}

export const BulletList = ({children, singleCol=false, highQtyCopy=false}) => {
    return (
        <Wrapper singleCol={singleCol} highQtyCopy={highQtyCopy}>
            {children}
        </Wrapper>
    )
}
